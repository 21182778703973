/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
import { SxProps } from '@mui/material';

export const productCardNameSX: SxProps = {
	whiteSpace: 'normal',
	overflowWrap: 'break-word',
	wordWrap: 'break-word',
	wordBreak: 'break-word',
	textAlign: 'left',
	color: 'text.main',
	fontWeight: 700,
};
