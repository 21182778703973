/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { MuiCardMedia } from '@/components/blocks/MuiCardMedia';
import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { ProductCardCompareBox } from '@/components/blocks/ProductCard/parts/CompareBox';
import {
	productCardActionAreaSX,
	productCardActionsSX,
	productCardSX,
} from '@/components/blocks/ProductCard/styles/card';
import { productCardContentSX } from '@/components/blocks/ProductCard/styles/cardContent';
import { productCardMediaSX } from '@/components/blocks/ProductCard/styles/cardMedia';
import { productCardNameSX } from '@/components/blocks/ProductCard/styles/name';
import { Swatch } from '@/components/blocks/Swatch';
import { useCompareCollector } from '@/data/Content/CompareCollector';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useProductCard } from '@/data/Content/_ProductCard';
import { useProductEvents } from '@/data/Content/_ProductEvents';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { ContentContext } from '@/data/context/content';
import { ProductType } from '@/data/types/Product';
import { Box, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useContext } from 'react';

/* compare checkbox is positioned absolutely so that entire card can be a link to PDP */

export const ProductCard: FC<{
	product: ProductType;
	clickAction?: () => void;
	externalPrice?: any;
}> = ({ product, clickAction, externalPrice }) => {
	const priceDisplayNLS = useLocalization('PriceDisplay');
	const productCardNLS = useLocalization('ProductCard');
	const router = useNextRouter();
	const { onSwatch, sku, formattedBvPartNumber } = useProductCard(product);
	const { compareEnabled } = useContext(ContentContext) as ReturnType<typeof useCompareCollector>;
	const { onClick } = useProductEvents({ product });
	const { user } = useUser();
	const isLoggedIn = user?.isLoggedIn ?? false;

	return (
		<Stack direction="column" alignItems="center" sx={productCardSX}>
			<Card
				component={Linkable}
				href={product.seo?.href}
				onClick={() => {
					const pathname = router.asPath.split('?').at(0);

					router.push(
						{
							pathname,
							query: {
								...router.query,
								scrollID: product.partNumber,
							},
						},
						undefined,
						{
							shallow: true,
						}
					);
					onClick(clickAction);
				}}
				sx={productCardActionAreaSX({ compareEnabled })}
				id={product.partNumber}
				data-testid={product.partNumber}
			>
				<MuiCardMedia
					sx={productCardMediaSX}
					component="div"
					image={(sku ?? product).thumbnailRaw}
					title={product.name}
				></MuiCardMedia>
				<CardContent sx={productCardContentSX}>
					<Stack spacing={1}>
						<Typography
							variant="body2AsH2"
							align="center"
							id={`${product.partNumber}-name`}
							data-testid={`${product.partNumber}-name`}
							sx={productCardNameSX}
						>
							{product.name}
						</Typography>
						{!isEmpty(product.colorSwatches) ? (
							<Stack direction="row" justifyContent="center" spacing={1}>
								{product.colorSwatches.map((colorSwatch) => (
									<Swatch
										key={colorSwatch.identifier}
										title={colorSwatch.identifier}
										size="medium"
										image={colorSwatch.image1path}
										data-testid={`product-${colorSwatch.identifier.toLowerCase()}-swatch`}
										id={`product-${colorSwatch.identifier.toLowerCase()}-swatch`}
										onClick={(event) => onSwatch(event, colorSwatch)}
									/>
								))}
							</Stack>
						) : null}
					</Stack>
				</CardContent>
				<Box sx={{ margin: '5px 0', padding: '0 16px' }}>
					<Stack
						direction="row"
						justifyContent="center"
						data-bv-show="inline_rating"
						data-bv-product-id={formattedBvPartNumber}
					></Stack>
					<Typography
						variant="body2AsH2"
						id={`${product.partNumber}-partNumber`}
						data-testid={`${product.partNumber}-partNumber`}
						sx={{ fontSize: '14px', wordBreak: 'break-word' }}
					>
						{productCardNLS.PartNuber.t({
							partNumber: product?.partNumber?.replace(/\((G|P)\)$/g, ''),
						})}
					</Typography>
				</Box>
				<Box>
					{isLoggedIn && externalPrice?.minSellPrice ? (
						<Typography variant="body1" align="center" sx={{ fontWeight: '700' }}>
							<PriceDisplay
								currency={product.productPrice.currency}
								min={externalPrice?.minSellPrice}
								{...(externalPrice?.minSellPrice !== externalPrice?.maxSellPrice
									? { max: externalPrice?.maxSellPrice }
									: {})}
								isAsLowAs={true}
							></PriceDisplay>
						</Typography>
					) : (
						<>
							{product.productPrice.min ? (
								<Typography variant="body1" align="center" sx={{ fontWeight: '700' }}>
									<PriceDisplay
										currency={product.productPrice.currency}
										min={product.productPrice.min}
										{...(product.productPrice.max ? { max: product.productPrice.max } : {})}
										isAsLowAs={true}
									></PriceDisplay>
								</Typography>
							) : (
								<Typography variant="body1" align="center">
									{priceDisplayNLS.Labels.Pending.t()}
								</Typography>
							)}
						</>
					)}
				</Box>
			</Card>
			<CardActions sx={productCardActionsSX}>
				<ProductCardCompareBox product={product} />
			</CardActions>
		</Stack>
	);
};
