/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const productCardSX: SxProps = {
	width: '100%',
	height: '100%',
	position: 'relative',
};

export const productCardActionAreaSX = ({
	compareEnabled = false,
}: {
	compareEnabled?: boolean;
}): SxProps => ({
	p: 2,
	pb: { xs: 2, sm: compareEnabled ? '58px' : 2 }, // space for absolutely positioned compare checkbox
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	cursor: 'pointer',
	'&:hover, &:active, &:focus': {
		boxShadow: 3,
		textDecoration: 'none',
		color: 'inherit',

		'.MuiTypography-body2AsH2': {
			color: 'text.primary',
		},
	},
});

export const productCardActionsSX: SxProps = {
	position: 'absolute',
	bottom: '16px',
	p: 0,
};
