import { useDiValImagePath } from '@/data/Content/DiValImagePath';
import { combineSX } from '@/utils/combineSX';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box, CardMedia, CardMediaTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ComponentProps, useState } from 'react';

type Props = ComponentProps<OverridableComponent<CardMediaTypeMap<Record<string, unknown>, 'div'>>>;
export const MuiCardMedia = (props: Props) => {
	const { image: img, sx, ...rest } = props;
	const { thumbnail, thumbnail2x } = useDiValImagePath(img);

	const [hasError, setHasError] = useState(img ? false : true);

	return hasError ? (
		<Box sx={combineSX([sx, { width: '100%' }])}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					maxWidth: '140px',
					maxHeight: '140px',
					width: '100%',
					padding: '50% 0',
					backgroundColor: '#bdbdbd',
				}}
			>
				<PhotoCameraIcon
					sx={{
						color: 'background.default',
					}}
				/>
			</Box>
		</Box>
	) : (
		<CardMedia
			{...rest}
			sx={sx}
			image={thumbnail}
			srcSet={`${thumbnail2x} 2x`}
			component="img"
			onError={() => setHasError(true)}
		/>
	);
};
