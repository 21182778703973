/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const productCardMediaSX: SxProps<Theme> = {
	maxWidth: '140px',
	maxHeight: '140px',
	margin: '0 auto 10px',
	objectFit: 'contain',
};
