/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useCompareCollector } from '@/data/Content/CompareCollector';
import { useLocalization } from '@/data/Localization';
import { ContentContext } from '@/data/context/content';
import { ProductType } from '@/data/types/Product';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC, InputHTMLAttributes, useContext, useMemo } from 'react';

type ProductCardCompareBoxProps = {
	product: ProductType;
};

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
	'data-compare'?: string;
}

export const ProductCardCompareBox: FC<ProductCardCompareBoxProps> = ({ product }) => {
	const { type, partNumber } = product;
	const { compareEnabled, compareState, onChange } = useContext(ContentContext) as ReturnType<
		typeof useCompareCollector
	>;
	const localization = useLocalization('compare');

	const disabled = useMemo(
		() => !!(!compareState?.checked[product.id] && compareState?.disabled),
		[compareState?.checked, compareState?.disabled, product.id]
	);

	const visibility = useMemo(() => {
		if (disabled) {
			return 'hidden';
		} else if (type === 'product' || type === 'item' || type === 'variant') {
			return 'visible';
		} else {
			return 'hidden';
		}
	}, [disabled, type]);

	return compareEnabled ? (
		<Stack sx={{ visibility }}>
			<FormControlLabel
				control={
					<Checkbox
						data-testid={`product-compare-${partNumber?.toLowerCase()}-checkbox`}
						id={`product-compare-${partNumber?.toLowerCase()}-checkbox`}
						disabled={disabled}
						checked={!!compareState.checked[product.id]}
						onChange={(e) => onChange(e, product)}
						inputProps={{ 'data-compare': 'true' } as CustomInputProps}
					/>
				}
				label={
					<Typography variant="caption" data-compare="true">
						{localization.compare.t()}
					</Typography>
				}
			/>
		</Stack>
	) : null;
};
